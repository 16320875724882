/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://y3e62l85gh.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://e4f6vrq36nbz7o75vhe5r5ecnq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-gnlahz6u3zfoffib4vjwemjjee",
    "aws_cognito_identity_pool_id": "us-east-1:7e63f3df-38f4-4b27-aa9b-2b39ac4c55ec",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Q0qHb2Yz0",
    "aws_user_pools_web_client_id": "6cv6ag0ave0nk30ggrhvrbs4rh",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "curltimewebsitecde7f2b1454d4540b0121c585d1ab4a7133332-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
